import $http from "@/utils/request";
import md5 from "crypto-js/md5";
import router from "@/router";
const state = {
  address: "", // 用户钱包地址
  refAddress: "", // 推荐人的钱包地址
  ivCode: "", // 邀请码
  authorization: "", // 用户token
  token: "", // 李老表的用户token
  propertys: {
    // "3"(当前拥有U数)  平台u  余额
    // "100"(VIP等级)、"101"(社区长  此属性的值>0表示为社区长)、"102"(购买VIP次数)、"103"(团队奖励等级)
    // "204"(上次下注时间)
    // "300"(铸造次数)、"303"(可提取的U数)
    // "700"(USDT总收益(个人))、
    // "701"(USDT昨日收益(个人))、
    // "702"(USDT贡献总收益(团队)))、
    // "703"(USDT贡献昨日收益(团队))、
    // "704"(USDT今日收益(个人))、
    // "705"(USDT贡献今日收益(团队))
    // "706"(USDT邀请总收益)
    // "900"(自己团队总人数)、"903"(直推用户数)
    // "1100"(充值总金额)
  },
  openTimes: { t: [], b: [] },
  // USDT: "", // 用户正儿八经的的u
  // MATIC: "", // 用户的马蹄
};

const mutations = {
  SET_openTimes: (state, openTimes) => (state.openTimes = openTimes),
  SET_ADDRESS: (state, address) => (state.address = address),
  /**
   * 登录
   * @param {*} state
   * @param {推荐人钱包} refAddress
   * @param {token} token
   */
  SET_TOKEN: (state, { refAddress, token, authorization }) => {
    state.token = token;
    state.refAddress = refAddress;
    state.authorization = authorization;
  },
  SET_REFADDRESS: (state, refAddress) => {
    state.refAddress = refAddress;
  },
  SET_IVCODE: (state, ivCode) => {
    state.ivCode = ivCode;
  },
  /**
   * 手动点击退出登录
   */
  outLoginFN(state) {
    state.address = state.token = state.authorization = "";
    state.propertys = {};
    router.push("/login");
  },
  /**
   * 只是token过期
   */
  outLogin(state) {
    state.address = state.token = state.authorization = "";
    state.propertys = {};
    router.push("/login");
  },
  /**
   * 修改用户属性
   * @param {*} state
   * @param {新属性替换旧属性} obj
   * @returns
   */
  SET_PROPERTYS: (state, obj) => (state.propertys = { ...state.propertys, ...obj }),
};

const actions = {
  /**
   * 登录
   * @param {推荐人钱包地址} refAddress
   * @returns
   */
  async loginFn({ commit, state }, refAddress) {
    const res = await $http.post("/auth/login", {
      userName: state.address,
      referee: refAddress.toLowerCase(),
      passWord: md5(state.address + "dsaioblockchain").toString(),
      version: "1.0.0",
    });
    commit("SET_TOKEN", { refAddress: res.data.referee, token: res.data.token, authorization: res.data.authorization });
    commit("SET_IVCODE", refAddress);
    // commit("SET_TOKEN", { refAddress: '12345678', token: '1', authorization: '1' });
    // commit("SET_IVCODE", refAddress);
    router.push("/home");
    return res.data;
  },
  /**
   * 获取用户属性
   * @param {属性  不穿刷新全部} array
   * @returns
   */
  async getPropertys({ commit, state }, array) {
    if (!state.token) return;
    const arr = ["1", "2", "3", "100", "101", "103", "104", "105", "207", "209", "301", "302", "303", "700", "701", "702", "703", "704", "705", "706", "900","901", "903",];
    const res = await $http.post("/app/getPropertys", { array: array || arr });
    commit(
      "SET_PROPERTYS",
      res.data.data.reduce((obj, v) => Object.assign(obj, v), {})
    );
    return state.propertys;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
